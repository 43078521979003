import Flag from 'react-world-flags';

// export const available_languages = [
//   'sv-se',
//   'en',
//   'en-us',
//   'en-gb',
//   'es',
//   'fr',
//   'de',
//   'no',
//   'dk',
//   'fi',
// ];

export const LANGUAGE_LABELS = {
  '': '',
  'sv': <span className='ms-2'><Flag code={'SE'} fallback={<span>Swedish</span>} height="8" className='me-1'/>Swedish</span>,
  'sv-se': <span className='ms-2'><Flag code={'SE'} fallback={<span>Swedish</span>} height="8" className='me-1'/> Swedish</span>,
  'en-gb': <span className='ms-2'><Flag code={'GB'} fallback={<span>British English</span>} height="8" className='me-1'/>British English</span>,
  'en-us': <span className='ms-2'><Flag code={'US'} fallback={<span>American English</span>} height="8" className='me-1'/>American English</span>,
  'es-es': <span className='ms-2'><Flag code={'ES'} fallback={<span>Spanish (Spain)</span>} height="8" className='me-1'/>Spanish (Spain)</span>,
  'es-mx': <span className='ms-2'><Flag code={'ES'} fallback={<span>Spanish (Mexico)</span>} height="8" className='me-1'/>Spanish (Mexico)</span>,
  'fr-fr': <span className='ms-2'><Flag code={'FR'} fallback={<span>French</span>} height="8" className='me-1'/>French</span>,
  'de-de': <span className='ms-2'><Flag code={'DE'} fallback={<span>German (Germany)</span>} height="8" className='me-1'/>German (Germany)</span>,
  'no-no': <span className='ms-2'><Flag code={'NO'} fallback={<span>Norwegian</span>} height="8" className='me-1'/>Norwegian</span>,
  'da-dk': <span className='ms-2'><Flag code={'DK'} fallback={<span>Danish</span>} height="8" className='me-1'/>Danish</span>,
  'fi-fi': <span className='ms-2'><Flag code={'FI'} fallback={<span>Finnish</span>} height="8" className='me-1'/>Finnish</span>,
  'it-it': <span className='ms-2'><Flag code={'IT'} fallback={<span>Italian</span>} height="8" className='me-1'/>Italian</span>,
  'nl-nl': <span className='ms-2'><Flag code={'NL'} fallback={<span>Dutch</span>} height="8" className='me-1'/>Dutch</span>,
  'tr-tr': <span className='ms-2'><Flag code={'TR'} fallback={<span>Turkish</span>} height="8" className='me-1'/>Turkish</span>,
  'pt-pt': <span className='ms-2'><Flag code={'PT'} fallback={<span>Portuguese</span>} height="8" className='me-1'/>Portuguese</span>,
  'el-gr': <span className='ms-2'><Flag code={'EL'} fallback={<span>Greek</span>} height="8" className='me-1'/>Greek</span>,
};

export const LANGUAGE_FLAGS = {
  '': '',
  'sv': <Flag code={'SE'} height="12" fallback={<span>Swedish</span>} className='me-1'/>,
  'sv-se': <Flag code={'SE'} height="12" fallback={<span>Swedish</span>} className='me-1'/>,
  'en-gb': <Flag code={'GB'} height="12" fallback={<span>British English</span>} className='me-1'/>,
  'en-us': <Flag code={'US'} height="12" fallback={<span>American English</span>} className='me-1'/>,
  'es-es': <Flag code={'ES'} height="12" fallback={<span>Spanish (Spain)</span>} className='me-1'/>,
  'es-mx': <Flag code={'ES'} height="12" fallback={<span>Spanish (Mexico)</span>} className='me-1'/>,
  'fr-fr': <Flag code={'FR'} height="12" fallback={<span>French</span>} className='me-1'/>,
  'de-de': <Flag code={'DE'} height="12" fallback={<span>German (Germany)</span>} className='me-1'/>,
  'no-no': <Flag code={'NO'} height="12" fallback={<span>Norwegian</span>} className='me-1'/>,
  'da-dk': <Flag code={'DK'} height="12" fallback={<span>Danish</span>} className='me-1'/>,
  'fi-fi': <Flag code={'FI'} height="12" fallback={<span>Finnish</span>} className='me-1'/>,
  'it-it': <Flag code={'IT'} height="12" fallback={<span>Italian</span>} className='me-1'/>,
  'nl-nl': <Flag code={'NL'} fallback={<span>Dutch</span>} height="8" className='me-1'/>,
  'tr-tr': <Flag code={'TR'} fallback={<span>Turkish</span>} height="8" className='me-1'/>,
  'pt-pt': <Flag code={'PT'} fallback={<span>Portuguese</span>} height="8" className='me-1'/>,
  'el-gr': <Flag code={'EL'} fallback={<span>Greek</span>} height="8" className='me-1'/>,
};

export const LANGUAGES = [
  { label: 'Select language', value: -1 },
  {
    label: LANGUAGE_LABELS.sv,
    value: 'sv-se'
  },
  {
    label: LANGUAGE_LABELS['en-gb'],
    value: 'en-gb'
  },
  {
    label: LANGUAGE_LABELS['en-us'],
    value: 'en-us'
  },
  {
    label: LANGUAGE_LABELS['es'],
    value: 'es-es'
  },
  {
    label: LANGUAGE_LABELS['es'],
    value: 'es-mx'
  },
  {
    label: LANGUAGE_LABELS['fr'],
    value: 'fr-fr'
  },
  {
    label: LANGUAGE_LABELS['de'],
    value: 'de-de'
  },
  {
    label: LANGUAGE_LABELS['no'],
    value: 'no-no'
  },
  {
    label: LANGUAGE_LABELS['dk'],
    value: 'da-dk'
  },
  {
    label: LANGUAGE_LABELS['fi'],
    value: 'fi-fi'
  },
  {
    label: LANGUAGE_LABELS['it'],
    value: 'it-it'
  },
  {
    label: LANGUAGE_LABELS['nl-nl'],
    value: 'nl-nl'
  },
  {
    label: LANGUAGE_LABELS['tr-tr'],
    value: 'tr-tr'
  },
  {
    label: LANGUAGE_LABELS['el-gr'],
    value: 'el-gr'
  },
  {
    label: LANGUAGE_LABELS['pt-pt'],
    value: 'pt-pt'
  },
];